import LocalTime from "local-time"
LocalTime.config.i18n["sq"] = {
    date: {
      dayNames: [
        "E dielë",
        "E hënë",
        "E martë",
        "E mërkurë",
        "E enjte",
        "E premte",
        "E shtunë",
      ],
      abbrDayNames: [
        "Die",
        "Hën",
        "Mar",
        "Mër",
        "Enj",
        "Pre",
        "Sht",
      ],
      monthNames: [
        "Janar",
        "Shkurt",
        "Mars",
        "Prill",
        "Maj",
        "Qershor",
        "Korrik",
        "Gusht",
        "Shtator",
        "Tetor",
        "Nëntor",
        "Dhjetor",
      ],
      abbrMonthNames: [
        "Jan",
        "Shk",
        "Mar",
        "Pri",
        "Maj",
        "Qer",
        "Kor",
        "Gus",
        "Sht",
        "Tet",
        "Nën",
        "Dhj",
      ],

      yesterday: "dje",
      today: "sot",
      tomorrow: "nesër",
      on: "më {date}",
      formats: {
        default: "%b %e, %Y",
        thisYear: "%b %e" },
    },
    time: {
      am: "am",
      pm: "pm",
      singular: "para {time}",
      singularAn: "para {time}",
      elapsed: "{time} më parë",
      second: "sekondë",
      seconds: "sekonda",
      minute: "minutë",
      minutes: "minuta",
      hour: "orë",
      hours: "orë",
      formats: {
        default: "%l:%M%P" }
      },
    datetime: {
      at: "{date} më {time}",
      formats: {
        default: "%B %e, %Y at %l:%M%P %Z"
      }
    }
  }
