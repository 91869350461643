document.addEventListener('turbolinks:load', function() {

  $("#index_views").change(function () {
    $("#go_btn_view_label").trigger('click');
  });

  $( "#filter_view_btn" ).on( "click", function(e) {
    $("#filter_card").toggle();
    $("#col-table").toggleClass( "col-md-9" );
    triggerHideShow('#filter_card');
  });

  $( "#expand_view_btn" ).on( "click", function(e) {
    $("#col-related-new").toggle();
    $("#col-related").toggleClass( "col-md-12" );
  });


  $( "#filter_view" ).on( "click", function(e) {
    $("#filter_card").toggle();
  });

  $('[data-toggle="popover"]').popover({
    html : true,
       content: function() {
         var content = $(this).attr("data-popover-content");
         return $(content).children(".popover-body").html();
       }
  });

  function triggerHideShow(id)
  {
      if ($(id).is(':visible'))
      {
          // $('[data-toggle="popover"]').popover('show');
      }
      else
      {
          $('[data-toggle="popover"]').popover('hide');
      }
  }
});
