// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")
require('jquery')
require('jquery-ui');

require("chartkick")
require("chart.js")
require("chartkick").use(require("highcharts"))
require('../packs/bootstrp_strength')
require('../packs/dropdown-hover')
require('../packs/calendar')
require('../packs/views')
require('../packs/dragtable')
require('../packs/local-time-sq')
require('../packs/local-time-mk')

require('jquery-validation')
require('bootstrap-duallistbox/src/bootstrap-duallistbox')

require('../stylesheets/application')

import $ from 'jquery';
import flatpickr from "flatpickr";
import tagsinput from "bootstrap-tagsinput";
import ResizableColumns from "jquery-resizable-columns";
import Chart from 'chart.js';
import 'select2';                       // globally assign select2 fn to $ element

import smartwizard from 'smartwizard'
import bootstrapDualListbox from "bootstrap-duallistbox/dist/jquery.bootstrap-duallistbox";


import LocalTime from "local-time"
LocalTime.start()
var user_locale = getCookie('locale');
LocalTime.config.locale = user_locale

import 'controllers'

function getCookie(n) {
    let a = `; ${document.cookie}`.match(`;\\s*${n}=([^;]+)`);
    return a ? a[1] : '';
};




global.toastr = require("toastr")
global.$ = $
global.jQuery = $


document.addEventListener('turbolinks:load', function() {

    // Collapse tsbox function
  $('.collapse-link').on('click', function (e) {
      e.preventDefault();
      var tsbox = $(this).closest('div.tsbox');
      var button = $(this).find('i');
      var content = tsbox.children('.tsbox-content');
      content.slideToggle(200);
      button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
      tsbox.toggleClass('').toggleClass('border-bottom');
      setTimeout(function () {
          tsbox.resize();
          tsbox.find('[id^=map-]').resize();
      }, 50);
  });

  // Close tsbox function
  $('.close-link').on('click', function (e) {
      e.preventDefault();
      var content = $(this).closest('div.tsbox');
      content.remove();
  });

  $('.tanisales-close-link').on('click', function (e) {
      e.preventDefault();
      var content = $(this).closest('#tanisales_admin');
      content.remove();
  });

  var element = document.querySelector("#boards")


});

document.addEventListener('turbolinks:load', function() {
  flatpickr("[data-behaviour='flatpickr']", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d"
  });


  $(function(){
    $("table").resizableColumns();
  });

  $('.dragtable').dragtable({
    dragHandle:'.drag-icon',
    dragaccept:'.accept',
    persistState: function(table) {
    var lastId = "";
    table.el.find('th').each(function(i) {
      console.log(this.id);
      if(this.id != '') {
        table.sortOrder[this.id]=i;
        lastId = this.id
      }
    });

    $.ajax({
      url: "/views/" + lastId + "/move_position",
      type: "POST",
      data: table.sortOrder,
      dataType: "json"
    });
  }
});


})

document.addEventListener('turbolinks:request-start', function() {
    $('#spinner').show();
});

document.addEventListener("turbolinks:request-end", function(){
    $('#spinner').hide();
});

document.addEventListener("turbolinks:load", function(){
    $('#spinner').hide();
});

document.addEventListener('turbolinks:load', function() {

  $('[data-toggle="popover"]').popover({ trigger: "hover" });
  $(function() {
      $(document).on("ajax:send", function(event) {
        $('#spinner').show();
      });
      $(document).on("ajax:success", function(event) {
        $('#spinner').hide();
      });
      $(document).on("ajax:error", function(event) {
        toastr.error(event.originalEvent.detail[0].slice(0,80), event.originalEvent.detail[1]);
        $('#spinner').hide();
      });
  });






  $(function() {
    $('#navbar-zgjerohu-16 .navbar-search-input > input').on('focus', function() {
      $('#navbar-zgjerohu-16 .navbar-search-box').addClass('active');
    });
    $('#navbar-zgjerohu-16 .navbar-search-cancel').on('click', function(e) {
      e.preventDefault();
      $('#navbar-zgjerohu-16 .navbar-search-box').removeClass('active');
    });
    $('[data-toggle="tooltip"]').tooltip();
  });
});





document.addEventListener('turbolinks:load', function() {
  (function() {
    window.layoutHelpers.setAutoUpdate(true);
  })();

  // Collapse menu
  (function() {
    if ($('#layout-sidenav').hasClass('sidenav-horizontal') || window.layoutHelpers.isSmallScreen()) {
      return;
    }

    try {
      window.layoutHelpers.setCollapsed(
        localStorage.getItem('layoutCollapsed') === 'true',
        false
      );
    } catch (e) {}
  })();

  $(function() {
    // Initialize sidenav
    $('#layout-sidenav').each(function() {
      new SideNav(this, {
        orientation: $(this).hasClass('sidenav-horizontal') ? 'horizontal' : 'vertical'
      });
    });

    // Initialize sidenav togglers
    $('body').on('click', '.layout-sidenav-toggle', function(e) {
      e.preventDefault();
      window.layoutHelpers.toggleCollapsed();
      if (!window.layoutHelpers.isSmallScreen()) {
        try { localStorage.setItem('layoutCollapsed', String(window.layoutHelpers.isCollapsed())); } catch (e) {}
      }
    });

    if ($('html').attr('dir') === 'rtl') {
      $('#layout-navbar .dropdown-menu').toggleClass('dropdown-menu-right');
    }
  });
});
