import LocalTime from "local-time"
LocalTime.config.i18n["mk"] = {
    date: {
      dayNames: [
        "Недела",
        "Понеделник",
        "Вторник",
        "Среда",
        "Четврток",
        "Петок",
        "Сабота",
      ],
      abbrDayNames: [
        "Нед",
        "Пон",
        "Вто",
        "Сре",
        "Чет",
        "Пет",
        "Саб",
      ],
      monthNames: [
        "Janar",
        "Shkurt",
        "Mars",
        "Prill",
        "Maj",
        "Qershor",
        "Korrik",
        "Gusht",
        "Shtator",
        "Tetor",
        "Nëntor",
        "Dhjetor",
      ],
      abbrMonthNames: [
        "Јан",
        "Фев",
        "Мар",
        "Апр",
        "Мај",
        "Јун",
        "Јул",
        "Авг",
        "Сеп",
        "Окт",
        "Ное",
        "Дек",
      ],

      yesterday: "вчера",
      today: "денес",
      tomorrow: "утре",
      on: "на {date}",
      formats: {
        default: "%b %e, %Y",
        thisYear: "%b %e" },
    },
    time: {
      am: "ам",
      pm: "пм",
      singular: "околу {time}",
      singularAn: "околу {time}",
      elapsed: "пред {time}",
      second: "секунда",
      seconds: "секунди",
      minute: "минута",
      minutes: "минути",
      hour: "час",
      hours: "часови",
      formats: {
        default: "%l:%M%P" }
      },
    datetime: {
      at: "{date} во {time}",
      formats: {
        default: "%B %e, %Y at %l:%M%P %Z"
      }
    }
  }
