import { Controller } from "stimulus"
import Rails from "@rails/ujs";
import validate from "validate.js"
export default class extends Controller {
  // when you define static targets the helper to access
  // will be the name of data-target and Targer - ex: statusTarget
  static targets = [ "form", "submit", "input", "price_book_name", "price_book_id" ]

  initialize() {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
  }
}
