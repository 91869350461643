import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  // when you define static targets the helper to access
  // will be the name of data-target and Targer - ex: statusTarget
  static targets = [ "status" ]

  initialize() {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
  }

  create_do() {
    var that = this
    var input = 'do_id=' + this.targets.element.id
    this.request_data('GET','/admin/crm_clusters/cluster_create', input, function (response) {
      if (response["errors"] !== undefined && response["errors"] != "") {
        that.statusTarget.innerText = "Error"
        toastr.error(response["errors"], 'Digital Ocean')
      } else {
        that.statusTarget.innerText = response["status"]
        toastr.success("Cluster: " + response["cluster"] +
                       "</br> </br> Status: " + response["status"],
                       'Digital Ocean')
      }
    })
  }

  backup() {
    var that = this
    var input = 'account_id=' + this.targets.element.id
    this.request_data('POST',`/admin/accounts/backup`, input, function (response) {
      if (response["errors"] !== undefined && response["errors"] != "") {
        toastr.error(response["errors"], 'Digital Ocean')
      } else {
        toastr.success("Response: " + response["results"],
                       'Digital Ocean')
      }
    })
  }

  cluster_users() {
    var that = this
    var input = 'do_id=' + this.targets.element.id
    this.request_data('GET','/admin/crm_clusters/cluster_users', input, function (response) {
      if (response["errors"] !== undefined && response["errors"] != "") {
        toastr.error(response["errors"], 'Digital Ocean')
      } else {
        toastr.success("Response: " + response["http_code"],
                       'Digital Ocean')
      }
    })
  }
  create_admin() {
    var that = this
    var input = 'do_id=' + this.targets.element.id
    this.request_data('GET','/admin/crm_clusters/create_admin', input, function (response) {
      if (response["errors"] !== undefined && response["errors"] != "") {
        toastr.error(response["errors"], 'Digital Ocean')
      } else {
        toastr.success("Response: " + response["http_code"],
                       'Digital Ocean')
      }
    })
  }

  cluster_update() {
    var that = this
    var input = 'do_id=' + this.targets.element.id
    this.request_data('GET','/admin/crm_clusters/cluster_update', input, function (response) {
      console.log(response)
      if (response["errors"] !== undefined && response["errors"] != "") {
        that.statusTarget.innerText = "Error"
        toastr.error(response["errors"], 'Digital Ocean')
      } else {
        that.statusTarget.innerText = response["status"]
        toastr.success("SQL Mode: " + response["sql_mode"]["http_code"],
                       'Digital Ocean')

        toastr.success("Firewall: " + response["firewall"]["http_code"],
                       'Digital Ocean')

        toastr.success("Cluster: " + response["cluster"] +
                       "</br> </br> Status: " + response["status"],
                       'Digital Ocean')
      }
    })
  }

  checkStatus() {
    var that = this
    var input = 'do_id=' + this.targets.element.id
    this.request_data('GET','/admin/crm_clusters/cluster_status', input, function (response) {
      if (that.empty_string(response["errors"]) == false) {
        console.log(that)
        that.statusTarget.innerText = "Error"
        toastr.error(response["errors"], 'Digital Ocean')
      } else {
        that.statusTarget.innerText = response["status"]
        toastr.success("Cluster: " + response["cluster"] +
                       "</br> </br> Status: " + response["status"],
                       'Digital Ocean')
      }
    })
  }

  paste(event) {
    event.preventDefault()
    console.log("Paste are not allowed!")
  }

  empty_string(str) {
    if (typeof str == 'undefined' || !str || str.length === 0 || str === "" || !/[^\s]/.test(str) || /^\s*$/.test(str) || str.replace(/\s/g,"") === "")
    {
        return true;
    }
    else
    {
        return false;
    }
  }

  request_data(type = 'GET', url, input, callback) {
   Rails.ajax({
     type: type,
     url: url,
     data: input,
     success: callback,
     error: function (response) { }
   })
  }
}
