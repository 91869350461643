import { Controller } from "stimulus"
import Rails from "@rails/ujs";
import validate from "validate.js"
export default class extends Controller {
  // when you define static targets the helper to access
  // will be the name of data-target and Targer - ex: statusTarget
  static targets = [ "form", "submit", "input", "price_book_name", "price_book_id" ]

  initialize() {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
  }

  submitForm(event) {
    event.preventDefault()

    var errors =  validate(this.formTarget, this.constraints())

    if (errors == null){
      this.formTarget.submit()
    } else {
      this.showErrors(errors || {})
    }
  }

  validateAsTyping(event) {
    var errors =  validate(this.formTarget, this.constraints())
    this.showErrors(errors || {})
  }

  showErrors(errors) {
    for (let input of this.inputTargets) {
      console.log(errors[input.name])
      this.showErrorsForInput(input, errors[input.name])
    }
  }

  showErrorsForInput(input, errors) {
    this.clearErrors(input)
    // var $parent = $(input).parents('.form-group');
    if (errors) {
      input.parentElement.classList.add('is-invalid')
      this.insertErrorMessages(input, errors)
    } else {
      input.parentElement.classList.remove('is-invalid')
    }
  }

  clearErrors(input){
    // We neeed this function to clear all errors from DOM - if they exist
    if (document.getElementById(`error_${input.name}`) != null) {
      document.getElementById(`error_${input.name}`).remove()
    }
  }

  insertErrorMessages(input, errors) {
    var html = document.createElement('div')
    html.innerHTML = errors.join('')
    html.id = `error_${input.name}`
    html.className = 'is-invalid'
    input.after(html)
  }

  constraints() {
    var constraints = {}
    for (let input of this.inputTargets) {
      constraints[input.name] = JSON.parse(input.getAttribute('data-validate'))
    }
    return constraints
  }

  currency_changed() {
    var that = this
    var input = 'currency_id=' + this.selectedCurrency
    if (this.selectedCurrency > 0) {
      this.request_data('GET','/price_books/pricebooks', input, function (response) {
        that.price_book_idTarget.value = response[0][1]
        that.price_book_nameTarget.value = response[0][0]
      })
    } else {
      that.price_book_idTarget.value = ""
      that.price_book_nameTarget.value = ""
    }
  }

  get selectedCurrency() {
    return this.currency.selectedOptions[0].value
  }

  get currency() {
    return this.targets.find('currency_input')
  }

  request_data(type = 'GET', url, input, callback) {
   Rails.ajax({
     type: type,
     url: url,
     data: input,
     success: callback,
     error: function (response) { }
   })
  }
}
